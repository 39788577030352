import React from 'react';
import Layout from '../components/layout';
import OverOnsDescription from '../components/OverOnsDescription';
import BookDemo from '../components/BookDemo';
import { SEO } from '../components/SEO/SEO';

const OverOnsPage = ({ location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Over Ons' }} />
    <OverOnsDescription />
    <BookDemo />
  </Layout>
);

export default OverOnsPage;
