import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const stats = [
  { label: 'Gesticht', value: '2021' },
  { label: 'Team', value: '5' },
  { label: 'Geregisteerde bedrijven', value: '2000+' },
  { label: 'Appstore review', value: '4.81' },
];

export default function OverOnsDescription() {
  return (
    <div className="relative bg-white py-16 sm:py-24">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
        <div className="relative sm:py-16 lg:py-0">
          <div
            aria-hidden="true"
            className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
          >
            <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
            <svg
              className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
            </svg>
          </div>
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
            <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
              <StaticImage
                src="../img/aboutus_image.png"
                alt=""
                className="absolute inset-0 h-full w-full object-cover"
              />

              <div className="absolute inset-0 bg-indigo-500 mix-blend-multiply" />
              <div className="absolute inset-0 bg-gradient-to-t from-secondary opacity-90" />
              <div className="relative px-8">
                <div>
                  <StaticImage src="../img/logo_white.png" alt="" className="h-12" />
                </div>
                <blockquote className="mt-8">
                  <div className="relative text-lg font-medium text-white md:flex-grow">
                    <svg
                      className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-400"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="relative">
                      Een droomhuis koop je niet, dat maak je. Het maken bleek echter een stuk
                      lastiger dan gedacht zo leerde ik als snel. Vooral de communicatie met de
                      aannemer verliep moeizaam en was er aan beide kanten een andere interpretatie
                      van wat was afgesproken. Dit kon en moest anders!
                    </p>
                  </div>

                  <footer className="mt-4">
                    <p className="text-base font-semibold text-white">
                      Joris van der Steuijt, Team Lead at Homigo
                    </p>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>

        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
          {/* Content area */}
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
              Met Homigo verbouw je, zonder gedoe.
            </h2>
            <div className="mt-6 text-gray-500 space-y-6">
              <p className="text-lg font-bold">
                Homigo is in leven geroepen om het leven van aannemers en huiseigenaren eenvoudiger
                te maken. Verbouwen is namelijk vaak een tijd van onzekerheid en chaos.
                Huiseigenaren hebben of: net hun droomhuis gekocht, of, ervoor gekozen hun huidige
                huis te verbouwen naar het huis van hun dromen.
              </p>
              <p className="text-base leading-7">
                Een mooie taak voor aannemers dus, maar niet altijd even makkelijk. Er komt een hoop
                kijken bij verbouwen en renoveren. Dromen en wensen moeten vertaald worden naar
                praktische werkbare plannen. Ook worden er steeds meer eisen gesteld aan bouwwerken
                vanuit de overheid. Aannemers moeten digitaliseren, zodat zij een consumentendossier
                opbouwen en zichzelf en hun klanten ervan verzekeren dat zij goed werk hebben
                opgeleverd.
              </p>
              <p className="text-base leading-7">
                Homigo helpt aannemers de wensen en dromen van hun klanten te documenteren,
                centraliseren en te plannen binnen één app. Met het doel om het effect van een goed
                thuiskomen te verwezenlijken. Dit geeft huiseigenaren grip op hun verbouwing en
                inzicht over wat er allemaal bij komt kijken. We halen hiermee onduidelijkheid weg
                en bieden overzicht, inzicht, structuur en ruimte voor zowel huiseigenaren, als
                aannemers.
              </p>
              <p className="text-base leading-7">
                Want het waarmaken van droomhuizen hoeft niet lastig te zijn….
              </p>
            </div>
          </div>

          {/* Stats section */}
          <div className="mt-10">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
              {stats.map((stat) => (
                <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">{stat.label}</dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
            <div className="mt-10">
              <a href="/blog" className="text-base font-medium text-indigo-600">
                Lees onze blog <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
